import type { ReactElement } from 'react';
import type { GetStaticProps, InferGetStaticPropsType } from 'next';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { Error404PageLayout } from '@storefront/lib-content/components/error404';
import { PageType } from '@storefront/lib-global';
import { MasterLayout } from '@storefront/lib-global/globalComponents/masterLayout';
import { Meta } from '@storefront/lib-global/globalComponents/seo/Meta';
import { get404PageData } from '@storefront/lib-global/services/get404PageData';

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  const page404Layout = await get404PageData(locale);
  return {
    props: {
      page404Layout: page404Layout || null,
      ...(await serverSideTranslations(locale || 'en', [
        'app-main-common',
        'lib-content-common',
        'lib-global-common',
        'lib-csa-common',
        'lib-global-countries-common',
      ])),
    },
    revalidate: 10,
  };
};

export default function Page404({ page404Layout }: InferGetStaticPropsType<typeof getStaticProps>): ReactElement {
  const { t } = useTranslation(['lib-global-common']);

  return (
    <MasterLayout>
      <Meta metaTitle={t('pagenotfound.title')} pageType={PageType.ERROR} />
      <Error404PageLayout page404Layout={page404Layout} />
    </MasterLayout>
  );
}
