import styled from 'styled-components';
import type { Page404LayoutSlot as Page404LayoutSlotSchema } from '@amplience/content-types/typings/s-page-404-layout';
import { NeedHelpModal } from '@storefront/lib-global/globalComponents/cart/needHelp';
import { media } from '@storefront/lib-global/stylings';
import { renderAmplienceSlotComponents } from '@storefront/lib-global/utils/amplienceUtils';
import { error404PageAmplienceComponents } from './error404PageAmplienceComponents';

type Page404LayoutSlotProps = {
  page404Layout: Page404LayoutSlotSchema;
};

const AmplienceComponents = {
  ...error404PageAmplienceComponents,
};

const S = {
  Page404Section: styled.section`
    .page-404-need-help {
      display: none;

      @media ${media.greaterThan('lg')} {
        display: block;
        right: 0;
      }
    }
  `,
};

export function Error404PageLayout({ page404Layout }: Page404LayoutSlotProps) {
  const { slotContent } = page404Layout || {};
  return (
    <S.Page404Section>
      {Array.isArray(slotContent) && renderAmplienceSlotComponents(slotContent, AmplienceComponents)}{' '}
      <NeedHelpModal isCartEmpty={false} className="page-404-need-help" />
    </S.Page404Section>
  );
}
