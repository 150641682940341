import { sendDataLayerEvent } from '../../utils/dataLayerInteractions';
import EventNamesType from '../eventNamesType';

type SelectBannerType = {
  component_header: string;
  component_type: string;
  link_text: string;
  link_url: string;
  index?: number;
};

export function sendSelectBannerEvent(bannerData: SelectBannerType) {
  sendDataLayerEvent(EventNamesType.select_banner, {
    custom_data: {
      index: 0,
      ...bannerData,
    },
  });
}
