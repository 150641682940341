import type { MouseEvent } from 'react';
import styled from 'styled-components';
import type { FullBannerType } from '@amplience/content-types/typings/c-full-banner';
import { maxWidthPartial, media } from '@storefront/lib-global/stylings';
import { sendSelectBannerEvent } from '@storefront/lib-global/utils/gtm/events/select_banner/sendSelectBannerEvent';
import { getLocalizedValue } from '@storefront/lib-global/utils/transformers';
import { BannerTypes } from '../../utils/bannerUtils';
import FullScreenBanner from './FullScreenBanner';
import FullWidthBanner from './FullWidthBanner';
import type { FullBannerComponentProps } from './bannerType';

const S = {
  FullBannerContainer: styled.div`
    & *:empty {
      display: none;
    }

    @media ${media.greaterThan('xx')} {
      ${maxWidthPartial()}
    }
  `,
};

const BannerType = {
  [BannerTypes.FULL_SCREEN]: FullScreenBanner,
  [BannerTypes.FULL_WIDTH]: FullWidthBanner,
};

export function FullBannerComponent({ bannerType, ...props }: FullBannerComponentProps) {
  const Component = BannerType[bannerType as FullBannerType];

  const handleClick = (event: MouseEvent<HTMLAnchorElement>) => {
    const element = event.currentTarget as HTMLAnchorElement;

    sendSelectBannerEvent({
      component_header: getLocalizedValue(props.bannerTitle?.text ?? '').trim(),
      component_type: `${bannerType ?? ''} Banner`.trim(),
      link_url: element.href,
      link_text: element.textContent ?? '',
    });
  };

  return (
    <S.FullBannerContainer>
      <Component {...props} onClick={handleClick} />
    </S.FullBannerContainer>
  );
}

export default FullBannerComponent;
