import type { ReactElement } from 'react';
import {
  BottomSpacingSchema,
  CTAButtonsSchema,
  CategoryGridItemSchema,
  FullBannerSchema,
} from '@amplience/content-types';
import { Spacer } from '@storefront/lib-global/globalComponents/spacer';
import { CategoryGridItem } from '../categoryGridItem';
import { CTAButtons } from '../ctaButtons';
import { FullBannerComponent } from '../singleAndOverlappingBanners';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
// export const error404PageAmplienceComponents: { [k: string]: (props: any) => ReactElement | null } = {
export const error404PageAmplienceComponents: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [k: string]: (props: any) => ReactElement | null;
} = {
  [FullBannerSchema.$id]: FullBannerComponent,
  [CTAButtonsSchema.$id]: CTAButtons,
  [CategoryGridItemSchema.$id]: CategoryGridItem,
  [BottomSpacingSchema.$id]: Spacer,
};
