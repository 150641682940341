import { useEffect, useState } from 'react';
import type { FlattenSimpleInterpolation } from 'styled-components';
import styled from 'styled-components';
import type { MediaFit, TextAlignment } from '@amplience/content-types/typings/c-full-banner';
import { useIntersectOnce } from '@storefront/lib-global/hooks/useIntersectOnce';
import { useMediaMatch } from '@storefront/lib-global/hooks/useMediaMatch';
import { useResizeScrollSize } from '@storefront/lib-global/hooks/useResizeScrollSize';
import { FlexibleTextPartial } from '@storefront/lib-global/partials/flexibleText';
import type { MediaPartialProps } from '@storefront/lib-global/partials/media';
import { MediaPartial } from '@storefront/lib-global/partials/media';
import { RichTextPartial } from '@storefront/lib-global/partials/richText';
import { TypographyStyles, TypographyStylesType, colours, media, spacing } from '@storefront/lib-global/stylings';
import { getLocalizedValue } from '@storefront/lib-global/utils/transformers';
import { animate, positions } from '../../utils/bannerUtils';
import { FullBannerButton } from './FullBannerButton';
import type { FullBannerComponentProps } from './bannerType';

export type FullScreenBannerProps = FullBannerComponentProps;

const S = {
  FullScreenBannerContainer: styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;

    @media ${media.greaterThan('lg')} {
      position: relative;
    }
  `,
  FullScreenMediaContainer: styled.div<{ $mediaFit?: MediaFit; $mediaHeight: number }>`
    height: ${({ $mediaHeight }) => ($mediaHeight > 656 ? $mediaHeight + 40 : 656)}px;
    order: 2;
    position: relative;

    & video {
      height: 100%;
      object-fit: ${({ $mediaFit }) => $mediaFit};
      position: absolute;
      width: 100%;
    }

    @media ${media.greaterThan('lg')} {
      ${({ $mediaHeight }) => ($mediaHeight > 656 ? `height:${$mediaHeight + 40}px;` : 'height:110vh;')}
    }
  `,
  FullScreenBannerDetails: styled.div<{ $position?: FlattenSimpleInterpolation }>`
    display: flex;
    flex-direction: column;
    gap: ${spacing.S};
    margin: 0 ${spacing.S};
    order: 1;
    overflow-wrap: break-word;

    @media ${media.greaterThan('lg')} {
      display: flex;
      flex-direction: column;
      margin: 0 ${spacing.S};
      max-width: 700px;
      position: absolute;
      ${({ $position }) => $position}
    }
  `,
  FullScreenBannerTitle: styled(FlexibleTextPartial)<{ $isIntersecting: boolean }>`
    margin: 0;
    opacity: 0;
    overflow-wrap: break-word;
    ${({ $isIntersecting }) => ($isIntersecting ? animate() : null)}

    @media (prefers-reduced-motion) {
      animation: none;
      opacity: 1;
    }
  `,
  FullScreenBannerDescription: styled(RichTextPartial)<{ $textColor?: string; $isIntersecting: boolean }>`
    color: ${({ $textColor }) => $textColor};
    opacity: 0;
    ${({ $isIntersecting }) => ($isIntersecting ? animate() : null)}

    & > p {
      margin: 0;
    }

    & a {
      color: ${({ $textColor }) => $textColor};
    }

    @media (prefers-reduced-motion) {
      animation: none;
      opacity: 1;
    }
  `,
  FullScreenBannerButtonsContainer: styled.ul`
    display: flex;
    flex-direction: column;
    gap: ${spacing.XS};
    order: 3;
    padding: 0;

    @media ${media.greaterThan('lg')} {
      max-width: 340px;
    }
  `,
};

export function FullScreenBanner({
  bannerTitle,
  bannerDescription,
  bannerMedia,
  bannerButtons,
  textAlignment,
  onClick,
}: FullScreenBannerProps) {
  const isDesktop = useMediaMatch(media.greaterThan('lg'));
  const currentMedia = isDesktop ? bannerMedia?.desktopMedia : bannerMedia?.mobileMedia;
  const [screenHeight, setScreenHeight] = useState(0);

  const { elementRef: intersectionRef, isIntersecting } = useIntersectOnce({
    threshold: 0.7,
  });
  const { elementRef: resizeRef, scrollHeight } = useResizeScrollSize({});

  useEffect(() => {
    const handleResize = () => setScreenHeight(window.innerHeight);
    handleResize();
    window.addEventListener('resize', handleResize, { passive: true });
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  const mediaHeight = screenHeight > scrollHeight ? screenHeight : scrollHeight;

  return (
    <S.FullScreenBannerContainer>
      <S.FullScreenMediaContainer $mediaFit={currentMedia?.mediaFit} $mediaHeight={mediaHeight}>
        <MediaPartial
          {...(currentMedia as MediaPartialProps)}
          height={100}
          width={100}
          isImageLayoutFill
          mediaFit={currentMedia?.mediaFit}
          videoQuality={currentMedia?.videoQuality}
          altText={currentMedia?.altText}
          key={currentMedia?.media?.name}
        />
      </S.FullScreenMediaContainer>
      <S.FullScreenBannerDetails $position={positions[textAlignment as TextAlignment]} ref={resizeRef}>
        {!!bannerTitle?.text && (
          <S.FullScreenBannerTitle
            {...bannerTitle}
            text={getLocalizedValue(bannerTitle?.text)}
            defaultTextColor={colours.BLACK}
            defaultTextSize={TypographyStylesType.HEADINGS_XX_LARGE}
            $isIntersecting={isIntersecting}
            ref={intersectionRef}
          />
        )}
        {!!bannerDescription && (
          <S.FullScreenBannerDescription
            text={getLocalizedValue(bannerDescription.richText)}
            fontSize={TypographyStyles.Body.Small.Medium}
            $textColor={bannerDescription.textColor as string}
            $isIntersecting={isIntersecting}
          />
        )}
        {!!bannerButtons?.length && isDesktop && (
          <S.FullScreenBannerButtonsContainer>
            {bannerButtons.map((button, idx) => (
              <li key={`${button.buttonText}-${idx}`}>
                <FullBannerButton {...button} onClick={onClick} />
              </li>
            ))}
          </S.FullScreenBannerButtonsContainer>
        )}
      </S.FullScreenBannerDetails>
      {!!bannerButtons?.length && !isDesktop && (
        <S.FullScreenBannerButtonsContainer>
          {bannerButtons.map((button, idx) => (
            <li key={`${button.buttonText}-${idx}`}>
              <FullBannerButton {...button} onClick={onClick} />
            </li>
          ))}
        </S.FullScreenBannerButtonsContainer>
      )}
    </S.FullScreenBannerContainer>
  );
}

export default FullScreenBanner;
